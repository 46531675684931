import React from "react"
import Link from "gatsby-link"
import passport from "../images/passport-2.svg"

export default function Hero(props) {

  	return (
	  	<section className="hero">
		  	<div className="container">
		  		<div className="hero__left animate--fadeDown">
		  			<img className="rellax" data-rellax-speed="-2" src={passport} alt=""/>
		  		</div>
		  		<div className="hero__right animate--fadeUp">
		  			<div className="hero__right__copy rellax" data-rellax-speed="2">
		  				<h1>
		  					Web Developer for Hire
		  					<small>(freelancer)</small>
		  				</h1>
		  				<p>Hey, Jase here, and I’d love to work on your next project with you. Front-end dev is where my heart is, but I pretty much cover the full stack. I’m also happy to get involved during the earlier design stages too!</p>
		  				<Link to="/contact" className="btn white">Get in touch</Link>
		  			</div>
		  		</div>
		  	</div>
	  	</section>
  	)
}