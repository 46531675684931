import React from "react"
import Link from "gatsby-link"
import piechart from "../images/dodgy-pie-chart.svg"

export default function CopyLeftImageRightHome(props) {
  return (
  	<section className="copy-left-image-right">
	  	<div className="container">
	  		<div className="copy-left-image-right__left">
	  			<h2 data-aos="fade-right">
  					A bit of this, a bit of that, and a whole lot of web dev
  					<small>(what I do)</small>
  				</h2>
  				<p data-aos="fade-right">To be honest, I do a bit of all-sorts. One month I might be knee deep in a front-end web project, the next I’ll be editing a shoot for some headshot videos, making adjustments to a website design, or clacking away at the keyboard trying to write a masterpiece.</p>
  				<p data-aos="fade-right">Chances are, if you’re a creative agency, a business, a start-up… I can help. Whether you’re getting me involved in an upcoming project, wanting input at creative and development stages, or you just need help with workload and can do with having <strong><em>that</em></strong> web dev job taken off your hands.</p>
  			  <p data-aos="fade-right" style={{margin: 0}}><Link to="/about" className="btn">What I do</Link></p>
          {/*<p data-aos="fade-up" data-aos-delay="10000"><small>Yup, this button again. Sensing a pattern?</small></p>*/}
	  		</div>
	  		<div className="copy-left-image-right__right">
	  			<img src={piechart} alt=""/>
	  		</div>
	  	</div>
  	</section>
  	)
}