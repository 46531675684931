import React from "react"
import { graphql } from "gatsby"
import Link from "gatsby-link"
import Rellax from "rellax"
import AOS from "aos"
import SEO from "../components/seo"
import Hero from "../components/hero"
import CTA from "../components/cta"
import CopyLeftImageRight from "../components/copy-left-image-right--home"
import ArticleCard from "../components/article-card"
import passport from "../images/passport-2.svg"
import passportPNG from "../images/passport-2.png"

var breakpointSM = 800;

class Home extends React.Component  {

  state = {
    posts: [],
  }

  componentDidMount() {
    this.setState({
      posts: this.props.data.allMarkdownRemark.edges,
    })

		AOS.init({
			duration: 800,
			easing: 'easeOutCubic',
      anchorPlacement: 'top-bottom'
		});

    var viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    if (viewportWidth > breakpointSM ) {
      var rellax = Rellax('.rellax');
    }
  }

  componentDidUpdate() {
    var secondArticle = document.querySelector('.latest-articles__cards .card-wrapper:nth-child(even)');
    if (secondArticle)
      secondArticle.setAttribute('data-aos-delay', '300');

    AOS.refresh();
  }

  searchPosts = (e) => {
    let currentItems = []
    let searchingItem = []
    if (e.target.value !== '') {
      currentItems = this.props.data.allMarkdownRemark.edges
      searchingItem = currentItems.filter(({ node }) => {
        
      })
    } else {
      searchingItem = this.props.data.allMarkdownRemark.edges
    }
    this.setState({
      posts: searchingItem,
    })
  }

  render() {

    return (
      <div>
        <SEO title="Jason Clixby" image={passportPNG} description="Hey, Jase here, and I’d love to work on your next project with you. Front-end dev is where my heart is, but I pretty much cover the full stack." />
	  		<Hero/>
	  		<CopyLeftImageRight/>
	  		<CTA/>
        <section className="latest-articles">
          <div className="container">
            <div className="latest-articles__heading">
              <h2><span role="img" aria-label="lightbulb">💡</span> Latest Posts</h2>
              <Link to="/blog" className="btn">All Posts</Link>
            </div>
            <div className="latest-articles__cards">
              {this.state.posts.map(({ node }, index) => (
                <ArticleCard 
                  title={node.frontmatter.fancyTitle ? node.frontmatter.fancyTitle : node.frontmatter.title}
                  slug={'/blog' + node.fields.slug}
                  image={node.frontmatter.image}
                  date={node.frontmatter.date}
                  readTime={node.frontmatter.readTime}
                  key={index}
                  index={index}
                  tags={node.frontmatter.tags}
                />
              ))}
            </div>
          </div>
        </section>
	    </div> 
    )
  }
}

export default Home

export const LatestArticlesQuery = graphql`
  query latestArticlesQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date]}, limit: 2) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            fancyTitle
            image
            readTime
            tags
          }
        }
      }
    }
  }
`
